import React, { useState } from "react"
import clsx from "clsx"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import CssBaseline from "@material-ui/core/CssBaseline"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"

//List Icons
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"

//Material Icons
import TheatersIcon from "@material-ui/icons/Theaters"
import InfoIcon from "@material-ui/icons/Info"
import RefreshIcon from "@material-ui/icons/Refresh"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import GetAppIcon from "@material-ui/icons/GetApp"
import ThreeDRotationIcon from "@material-ui/icons/ThreeDRotation"
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye"

//Pdf
import pdf from "../../images/Electra.pdf"

//Modal
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

import siteMetadata from "../helpers/siteMetadata"
import Button from "@material-ui/core/Button"

import styled from "styled-components"
import { TextField } from "@material-ui/core"

const drawerWidth = 300

const MyAppBar = styled(AppBar)`
  display: flex;
  background-color: #000;
  opacity: 0.85;
  padding: 10px;
  color: white;

  .siteTitle {
    flex: 3;
    font-size: 2.4rem;

    @media (max-width: 1200px) {
      font-size: 2rem;
    }

    @media (max-width: 500px) {
      font-size: 1.5rem;
    }
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    marginBottom: "2rem",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "white",
  },
  icons: {
    color: "white",
  },
  divider: {
    background: "white",
  },
  hide: {
    display: "none",
  },
  arrow: {
    color: "white",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#000",
    color: "white",
    paddingLeft: theme.spacing(3),
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}))

export default function PersistentDrawerLeft() {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = useState(false)

  const [modal, setModal] = useState(false)
  const [pistasModal, setPistasModal] = useState(false)
  const [codeTextField, setCodeTextField] = useState("")
  const [pistasModalError, setPistasModalError] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleModal = () => {
    if (typeof window !== "undefined") {
      localStorage.clear()
      window.location.reload()
    }
  }

  const handlePistasModal = () => {
    if (
      typeof window !== "undefined" &&
      codeTextField.toLowerCase() === "quieropistas"
    ) {
      localStorage.setItem("pistas", "true")
      window.location.reload()
    } else {
      setPistasModalError(true)
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MyAppBar
        position="static"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h4" noWrap className="siteTitle">
            {siteMetadata().title}
          </Typography>
        </Toolbar>
      </MyAppBar>
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={open}
        ModalProps={{ onBackdropClick: handleDrawerClose }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon className={classes.arrow} />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        {/* <ListItem
            button
            component="a"
            target="_blank"
            href="https://teatroperezgaldos.es/"
          >
            <ListItemIcon>
              <TheatersIcon className={classes.icons} />
            </ListItemIcon>
            <ListItemText primary={"Auditorio A. Kraus"} />
          </ListItem> */}

        <List>
          {typeof window !== "undefined" &&
          localStorage.getItem("pistas") &&
          JSON.parse(localStorage.getItem("pistas")) === true ? (
            <ListItem
              button
              onClick={() => {
                localStorage.setItem("pistas", "false")
                window.location.reload()
              }}
            >
              <ListItemIcon>
                <RemoveRedEyeIcon className={classes.icons} />
              </ListItemIcon>
              <ListItemText primary={"Desactivar pistas"} />
            </ListItem>
          ) : (
            <ListItem button onClick={() => setPistasModal(true)}>
              <ListItemIcon>
                <RemoveRedEyeIcon className={classes.icons} />
              </ListItemIcon>
              <ListItemText primary={"Activar pistas"} />
            </ListItem>
          )}

          <ListItem button onClick={() => setModal(true)}>
            <ListItemIcon>
              <RefreshIcon className={classes.icons} />
            </ListItemIcon>
            <ListItemText primary={"Reiniciar juego"} />
          </ListItem>

          <ListItem
            button
            component="a"
            href="https://www.gobiernodecanarias.org/icigualdad/"
          >
            <ListItemIcon>
              <ExitToAppIcon className={classes.icons} />
            </ListItemIcon>
            <ListItemText primary={"Salir"} />
          </ListItem>
        </List>
      </Drawer>

      {pistasModal === true ? (
        <Dialog
          open={pistasModal}
          onClose={() => setPistasModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">¿En serio?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Escribe el código para activar las pistas.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="codigo"
              label="Código"
              type="text"
              fullWidth
              value={codeTextField}
              onChange={e => setCodeTextField(e.target.value)}
            />
            {pistasModalError ? (
              <p style={{ color: "red" }}>
                El código introducido no es correcto
              </p>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => handlePistasModal()}
            >
              Activar
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        ""
      )}

      {modal === true ? (
        <Dialog
          open={modal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">¿En serio?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿De verdad quieres reiniciar y perder el progreso?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => handleModal()}>
              Si
            </Button>
            <Button variant="outlined" onClick={() => setModal(false)}>
              No
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        ""
      )}
    </div>
  )
}
