import { createTheme } from "@material-ui/core/styles"

export const theme = createTheme({
  palette: {
    primary: {
      main: "#4c2882",
    },
    secondary: {
      main: "#9b1924",
    },
    spacing: [0, 2, 3, 5, 8],
    success: {
      main: "#4caf50",
      darker: "#358538",
    },
    error: {
      main: "#d32f2f",
    },
    info: {
      main: "#333",
    },
    header: {
      title: "#ddd",
      bg: "#0e0e0e",
    },
    footer: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Permanent Marker", "Andika"],
    h1: {
      letterSpacing: ".1rem",
    },
    h2: {
      letterSpacing: ".1rem",
    },
    h3: {
      letterSpacing: ".1rem",
    },
    h4: {
      letterSpacing: ".1rem",
    },
    button: {
      letterSpacing: ".1rem",
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiDialogContentText: {
      root: {
        fontFamily: "Andika",
      },
    },
    MuiDialogTitle: {
      root: {
        fontFamily: "Andika",
      },
    },
    MuiInputBase: {
      root: {
        width: "100%",
      },
    },
    MuiAppBar: {
      root: {
        flexGrow: 1,
      },
    },
  },
  props: {},
})
