import React from "react"
import styled from "styled-components"
import { theme } from "./theme"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import logos from "../../images/logos-white.png"
import logoPacto from "../../images/logopactoblancosinfondo.png"
import logoGobierno from "../../images/logo_ministerio-vg.jpeg"

const Footer = styled.footer`
  background-color: #000;
  border-color: ${theme.palette.primary.main};
  width: 100%;
  margin-top: 100px;
  /* border-top: 1px solid ${theme.palette.primary.main}; */
  padding: 40px 0;
  -webkit-box-shadow: 0px -3px 51px 30px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px -3px 51px 30px rgba(0,0,0,0.75);
  box-shadow: 0px -3px 51px 30px rgba(0,0,0,0.75);

  @media (max-width: 1650px) {
    width: 100%;
    padding: 30px 0;
  }

  div.logos {
    justify-content: space-between;
    display: flex;

    a {
      img {
        display: inline-block;
        height: 80px;
        @media (max-width: 350px) {
          height: 60px;
        }
      }
    }

    @media (max-width: 1650px) {
      flex-basis: 33.6%;
    }
  }

  /* logos finished */

  ul.legal {
    display: flex;
    flex-basis: 100%;
    justify-content: center;
    list-style: none;
    padding: 0;
    text-align: center;
    color: ${theme.palette.footer.main};
    font-size: 14px;
    line-height: 2;
    opacity: 0.7;

    li {
      font-size: 14px;
      line-height: 2;

      li:first-child:before {
        display: none;
      }

      &:before {
        content: "|";
        display: inline-block;
        margin: 0 18px;
      }

      a {
        text-decoration: none;
        color: ${theme.palette.footer.main};
        opacity: 0.75;
          &:hover {
          opacity: 1;
        }
      }

      
      &:first-child:before {
        display: none;
      }
    }

    li.break {
      display: none;
      flex-basis: 100%;
      height: 0;
      overflow: hidden;
      width: 0;

      @media (max-width: 750px) {
        display: block;
      }
    }
    @media (max-width: 1650px) {
      margin-top: 40px;
    }
    @media (max-width: 1000px) {
      margin: 43px 0 0;
    }
    @media (max-width: 750px) {
      flex-wrap: wrap;
      margin: 32px 0 0;
    }
  }
  /* legal finished */
`

const LogoContainer = styled.div`
  img {
    height: auto;
    width: 200px;
    @media only screen and (max-width: 600px) {
      width: 150px;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
      width: 150px;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
      width: 180px;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
      width: 200px;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
      width: 180px;
    }
  }
`

const RightLogoContainer = styled.div`
  img {
    height: auto;
    width: 300px;
    padding-right: 20px;

    @media only screen and (max-width: 600px) {
      width: 200px;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
      width: 250px;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
      width: 280px;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
      width: 300px;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
      width: 300px;
    }
  }
`
const BottomtLogoContainer = styled.div`
  img {
    height: auto;
    width: 300px;
    @media only screen and (max-width: 600px) {
      width: 200px;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
      width: 250px;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
      width: 280px;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
      width: 300px;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
      width: 330px;
    }
  }
`

export default function renderFooter() {
  return (
    <Footer>
      <Container>
        <Grid justify="center" container>
          <Grid item lg={4} md={6} sm={8} xs={10}></Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LogoContainer>
            <img src={logoPacto} />
          </LogoContainer>
          <RightLogoContainer>
            {/* <img style={{ paddingRight: 40 }} src={logos} alt="" /> */}
            <img src={logoGobierno} />
          </RightLogoContainer>
        </div>
        <BottomtLogoContainer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <img src={logoGobierno} style={{ width: "300px", height: "auto" }} /> */}
          <img src={logos} alt="" />
        </BottomtLogoContainer>

        <ul className="legal">
          <li>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.gobiernodecanarias.org/principal/avisolegal.html"
            >
              Aviso legal
            </a>
          </li>{" "}
          <li>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.gobiernodecanarias.org/principal/sugerenciasyreclamaciones/"
            >
              Sugerencias y reclamaciones
            </a>
          </li>
        </ul>
      </Container>
    </Footer>
  )
}
